import { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import { EditOutlined, VisibilityOutlined } from '@material-ui/icons';
import TableBuilder from 'components/Table';
import i18n from 'i18n';
import {
  Action,
  ProtectedDeleteDialog,
  ProtectedIconButton,
  Resource
} from 'components/Authorization';

interface NewswireServicesI {
  newswireServices: $Lns.NewswireService[];
  newswireFormats: $Lns.NewswireFormat[];
  onShow?: (newswireService: $Lns.NewswireService) => void;
  onEdit?: (newswireService: $Lns.NewswireService) => void;
  onDelete?: (newswireServiceGuid: string) => void;
  onRowClick?: (rowItem: { guid: { value: string } }) => void;
}

const NewswireServices: FunctionComponent<NewswireServicesI> = ({
  newswireServices,
  newswireFormats,
  onShow,
  onEdit,
  onDelete,
  onRowClick
}: NewswireServicesI) => {
  const rows = [
    i18n.t('pages.administration.newswires.tableRow.title'),
    i18n.t('pages.administration.newswires.tableRow.formatType')
  ];

  if (onEdit || onDelete || onShow) {
    rows.push(i18n.t('pages.administration.newswires.tableRow.actions'));
  }

  const findFormatName = (formatSearchGuid: string) => {
    const formatObj = newswireFormats.find(o => o.guid === formatSearchGuid);
    return formatObj?.name;
  };

  const cells = newswireServices.map(
    (newswireService: $Lns.NewswireService) => {
      return {
        fields: {
          title: { value: newswireService.name },
          format: { value: findFormatName(newswireService.format) },
          guid: { value: newswireService.guid, hidden: true },
          ...((onEdit || onDelete || onShow) && {
            actions: {
              value: (
                <Box width={1} display="flex">
                  {onShow && (
                    <ProtectedIconButton
                      size="small"
                      onClick={() => onShow(newswireService)}
                      lnsResource={Resource.NEWSWIRE}
                      lnsAction={Action.READ}
                    >
                      <VisibilityOutlined color="secondary" />
                    </ProtectedIconButton>
                  )}
                  {onEdit && (
                    <ProtectedIconButton
                      size="small"
                      onClick={() => onEdit(newswireService)}
                      lnsResource={Resource.NEWSWIRE}
                      lnsAction={Action.EDIT}
                    >
                      <EditOutlined color="secondary" />
                    </ProtectedIconButton>
                  )}
                  {onDelete && (
                    <ProtectedDeleteDialog
                      size="small"
                      onDelete={() => onDelete(newswireService.guid || '')}
                      tooltip={i18n.t('pages.assignments.tooltip.delete')}
                      target={i18n.t(
                        'pages.administration.newswires.dialog.title'
                      )}
                      targetDeletePrompt={i18n.t(
                        'pages.administration.newswires.dialog.deletePrompt'
                      )}
                      targetDeleteWarning={i18n.t(
                        'pages.administration.newswires.dialog.deleteWarning'
                      )}
                      lnsResource={Resource.TASK}
                    />
                  )}
                </Box>
              )
            }
          })
        }
      };
    }
  );

  return (
    <TableBuilder
      rows={rows}
      cells={cells}
      onRowClick={onRowClick}
      isDraggable
    />
  );
};

export default NewswireServices;

NewswireServices.defaultProps = {
  onEdit: undefined,
  onShow: undefined,
  onDelete: undefined,
  onRowClick: undefined
};
