import { FunctionComponent, MouseEvent } from 'react';
import { Box } from '@material-ui/core';
import { EditOutlined, VisibilityOutlined } from '@material-ui/icons';
import DeleteDialog from 'components/DeleteDialog';
import { useCanAccess } from 'components/Authorization/utils';
import { ProtectedIconButton } from 'components/Authorization/components/ProtectedIconButton';
import { Action } from 'components/Authorization/consts';
import i18n from 'i18n';

interface ProtectedAdminActionFieldI {
  lnsResource?: string;
  lnsOwners?: string[];
  target: string;
  targetDeleteTitle?: string;
  targetDeletePrompt?: string;
  targetDeleteWarning?: string;
  onShow?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
}

export const ProtectedAdminActionField: FunctionComponent<ProtectedAdminActionFieldI> =
  ({
    lnsResource,
    lnsOwners,
    target,
    targetDeleteTitle,
    targetDeletePrompt,
    targetDeleteWarning,
    onShow,
    onEdit,
    onDelete
  }: ProtectedAdminActionFieldI) => {
    const isCanDelete = useCanAccess(lnsResource, Action.DELETE, lnsOwners);

    const onEditHandler = (e: MouseEvent) => {
      e.stopPropagation();
      if (onEdit) onEdit();
    };

    return (
      <Box width={1} display="flex">
        {onShow && (
          <ProtectedIconButton
            size="small"
            onClick={onShow}
            lnsResource={lnsResource}
            lnsAction={Action.READ}
            lnsOwners={lnsOwners}
          >
            <VisibilityOutlined color="secondary" />
          </ProtectedIconButton>
        )}
        {onEdit && (
          <ProtectedIconButton
            size="small"
            onClick={onEditHandler}
            lnsResource={lnsResource}
            lnsAction={Action.EDIT}
            lnsOwners={lnsOwners}
          >
            <EditOutlined color="secondary" />
          </ProtectedIconButton>
        )}
        {onDelete && (
          <DeleteDialog
            size="small"
            onDelete={onDelete}
            target={target}
            lnsResource={lnsResource}
            lnsAction={Action.DELETE}
            targetDeleteTitle={targetDeleteTitle}
            targetDeletePrompt={targetDeletePrompt}
            targetDeleteWarning={targetDeleteWarning}
            btnDisabled={!isCanDelete}
            tooltip={
              isCanDelete
                ? undefined
                : (i18n.t('authorization.tooltip.notAuthorized') as string)
            }
          />
        )}
      </Box>
    );
  };

ProtectedAdminActionField.defaultProps = {
  lnsResource: undefined,
  lnsOwners: [],
  targetDeleteTitle: undefined,
  targetDeletePrompt: undefined,
  targetDeleteWarning: undefined,
  onEdit: undefined,
  onShow: undefined,
  onDelete: undefined
};
