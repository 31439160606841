import { FunctionComponent, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUser,
  setCurrentSsoUser,
  setCurrentLnsUser,
  purgeSession
} from 'pages/signin/actions';
import { setRundownDate } from 'pages/rundowns/actions';
import { removeTokens, tokenExpired } from 'utils/auth';
import toggleNotification from 'actions/notifications';
import MainLayout from 'components/MainLayout';
import AuthedRoutes from 'routes/Authed';
import RealTimeUpdates from 'components/RealTimeUpdates';
import DataLoader from 'components/Story/DataLoader';
import PermissionLoader from 'components/Authorization/components/PermissionLoader';

const LoggedInMain: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, toggleLoading] = useState(true);
  const token = localStorage.getItem('token');
  const tokenExpiresOn = localStorage.getItem('tokenExpiresOn');

  const { currentSsoUser, rundownDate } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  useEffect(() => {
    if (rundownDate === undefined) dispatch(setRundownDate());
    // check if token is expired and redirect to signin
    // TODO replace later with refreshing tokens instead of logging out
    if (tokenExpired(tokenExpiresOn)) {
      removeTokens();
      navigate('/signin');
      return;
    }
    // when token exist but currentSsoUser is not set in redux store
    // fetch user from api and store currentSsoUser in redux store
    if (!currentSsoUser && token) {
      fetchUser()
        .then(userJson => {
          if (userJson.code === 401) {
            removeTokens();
            navigate('/signin');
            return;
          }
          dispatch(setCurrentSsoUser(userJson as unknown as $Lns.SsoUser));
          dispatch(setCurrentLnsUser(userJson as unknown as $Lns.SsoUser));
        })
        .catch(() => toggleLoading(false));
    } else {
      toggleLoading(false);
    }
  }, [dispatch, rundownDate, currentSsoUser, token, tokenExpiresOn, navigate]);

  const handleLogout = () => {
    removeTokens();
    dispatch(toggleNotification('Logout successful!', 'success'));
    navigate('/signin');
    setImmediate(() => {
      dispatch(purgeSession());
    });
  };

  if (isLoading && !currentSsoUser) return <></>;

  if (!currentSsoUser && !isLoading) return <Navigate to="/signin" />;

  return (
    <DataLoader>
      <PermissionLoader>
        <RealTimeUpdates>
          <MainLayout handleLogout={handleLogout}>
            <AuthedRoutes />
          </MainLayout>
        </RealTimeUpdates>
      </PermissionLoader>
    </DataLoader>
  );
};

export default LoggedInMain;
