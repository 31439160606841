import { makeStyles } from '@material-ui/core';
import colors from 'styles/colors';

const paginationStyles = makeStyles({
  row: {
    padding: '12px 16px 24px',
    display: 'flex',
    gap: '8px',
    justifyContent: 'end'
  },
  buttonGroup: {
    boxShadow: '0px 1px 2px 0px rgba(10, 13, 18, 0.05)',
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    fontWeight: 600,
    color: colors.gray[700],
    padding: '8px 16px',
    border: `1px solid ${colors.gray[300]}`,
    borderRadius: '8px',
    background: 'none',

    '&.Mui-disabled': {
      color: colors.gray[500],
      background: 'none !important'
    }
  },
  buttonSelected: {
    fontWeight: 'bold',
    color: colors.gray[800],
    background: colors.gray[200]
  },
  buttonLabel: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center'
  },
  perPage: {
    fontWeight: 600,
    color: colors.gray[700],
    display: 'flex',
    gap: '8px',
    alignItems: 'center'
  },
  perPageLabel: {
    fontSize: '14px'
  },
  perPageSelect: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    color: colors.gray[700],
    padding: '8px 16px',
    border: `1px solid ${colors.gray[300]}`,
    borderRadius: '8px',
    background: 'none',

    '&:focus-within': {
      background: 'none !important'
    }
  }
});

export default paginationStyles;
