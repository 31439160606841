import { FunctionComponent, ReactNode } from 'react';
import type { IconButtonProps } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import i18n from 'i18n';
import Dialog from 'components/Dialog';

export interface ActionDialogI extends IconButtonProps {
  target: string;
  tooltip?: string;
  icon: ReactNode;
  targetDeleteTitle?: string;
  targetDeletePrompt?: string;
  targetDeleteWarning?: string;
  btnDisabled?: boolean;
  onConfirm: () => void;
}

const ActionDialog: FunctionComponent<ActionDialogI> = ({
  target,
  tooltip,
  icon,
  targetDeleteTitle,
  targetDeletePrompt,
  targetDeleteWarning,
  btnDisabled = false,
  onConfirm,
  ...props
}: ActionDialogI) => {
  return (
    <Dialog
      title={targetDeleteTitle || ''}
      tooltip={tooltip}
      openBtnLabel={i18n.t('deleteDialog.openBtnLabel')}
      iconBtn={icon}
      cancelLabel={i18n.t('button.cancel')}
      confirmLabel={i18n.t('button.confirm')}
      onConfirmCb={onConfirm}
      btnDisabled={btnDisabled}
      {...props}
    >
      <Typography gutterBottom variant="body2">
        {targetDeletePrompt}
      </Typography>
      <Typography gutterBottom variant="h6">
        {target}
      </Typography>
      <Typography gutterBottom variant="body2">
        {targetDeleteWarning}
      </Typography>
    </Dialog>
  );
};

export default ActionDialog;

ActionDialog.defaultProps = {
  targetDeleteTitle: i18n.t('deleteDialog.title'),
  tooltip: i18n.t('deleteDialog.tooltip'),
  targetDeletePrompt: i18n.t('deleteDialog.prompt'),
  targetDeleteWarning: i18n.t('deleteDialog.warning'),
  btnDisabled: false
};
