import { makeStyles } from '@material-ui/core';

const tableStyles = makeStyles({
  root: {
    minWidth: 275
  },
  tableContainer: {
    maxHeight: 800,
    maxWidth: '100vw',
    marginBottom: '5%'
  },
  footerRow: {
    width: '100%',
    padding: '12px 16px 8px',
    display: 'flex',
    justifyContent: 'end'
  }
});

export default tableStyles;
