import React, { FunctionComponent, ReactNode } from 'react';
import { Box } from '@material-ui/core';
import {
  EditOutlined,
  CheckBox,
  CheckBoxOutlineBlank
} from '@material-ui/icons';
import i18n from 'i18n';
import TableBuilder from 'components/Table';
import {
  ProtectedDeleteDialog,
  ProtectedIconButton,
  Resource,
  Action
} from 'components/Authorization';

interface RundownTemplateListI {
  rundownTemplates: $Lns.RundownTemplate[];
  onEdit: (rundownTemplate: $Lns.RundownTemplate) => void;
  onDelete: (rundownTemplate: $Lns.RundownTemplate) => void;
}

const RundownTemplateList: FunctionComponent<RundownTemplateListI> = ({
  rundownTemplates,
  onEdit,
  onDelete
}: RundownTemplateListI) => {
  const rows = [
    i18n.t('pages.administration.rundownTemplates.tableRow.name'),
    i18n.t('pages.administration.rundownTemplates.tableRow.start'),
    i18n.t('pages.administration.rundownTemplates.tableRow.end'),
    i18n.t('pages.administration.rundownTemplates.tableRow.mon'),
    i18n.t('pages.administration.rundownTemplates.tableRow.tue'),
    i18n.t('pages.administration.rundownTemplates.tableRow.wed'),
    i18n.t('pages.administration.rundownTemplates.tableRow.thu'),
    i18n.t('pages.administration.rundownTemplates.tableRow.fri'),
    i18n.t('pages.administration.rundownTemplates.tableRow.sat'),
    i18n.t('pages.administration.rundownTemplates.tableRow.sun'),
    i18n.t('tableRow.actions')
  ];

  const cells = rundownTemplates.map(rundownTemplate => {
    const props = {
      fields: {
        name: { value: rundownTemplate.name },
        start: { value: rundownTemplate.playoutStartTime },
        end: { value: rundownTemplate.playoutEndTime }
      }
    } as { fields: { [key: string]: { value: string | ReactNode } } };

    Object.keys(rundownTemplate.schedule).forEach((day: string) => {
      // eslint-disable-next-line
      // @ts-ignore
      const checked = rundownTemplate.schedule[day];
      // eslint-disable-next-line
      props.fields[day] = {
        value: checked ? (
          <CheckBox color="primary" />
        ) : (
          <CheckBoxOutlineBlank color="primary" />
        )
      };
    });

    // eslint-disable-next-line
    props.fields.actions = {
      value: (
        <Box display="flex">
          <ProtectedIconButton
            size="small"
            onClick={() => onEdit(rundownTemplate)}
            lnsResource={Resource.RUNDOWN_TEMPLATE}
            lnsAction={Action.EDIT}
          >
            <EditOutlined color="secondary" />
          </ProtectedIconButton>
          <ProtectedDeleteDialog
            size="small"
            lnsResource={Resource.RUNDOWN_TEMPLATE}
            onDelete={() => {
              onDelete(rundownTemplate);
            }}
            target={rundownTemplate.name}
            btnDisabled={false}
          />
        </Box>
      )
    };

    return props;
  });

  return <TableBuilder rows={rows} cells={cells} />;
};

export default RundownTemplateList;
