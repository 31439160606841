import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { nestedItems } from 'constants/administration';
import { useNavigate } from 'react-router';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  MenuItem,
  Popover,
  Typography
} from '@material-ui/core';
import {
  HomeOutlined,
  AssignmentIndOutlined,
  ListAltOutlined,
  RssFeedOutlined,
  DnsOutlined,
  FolderOutlined,
  SettingsOutlined,
  VerticalSplitRounded,
  ExpandMore,
  ExpandLess,
  PersonOutlined,
  FormatAlignLeftOutlined,
  FormatAlignRightOutlined,
  ExitToAppOutlined
} from '@material-ui/icons';
import mainSidebarStyles from 'styles/mainSidebar';
import clsx from 'clsx';
import i18n from 'i18n';
import {
  ProtectedSidebarListItem,
  Resource,
  Action,
  useCanAccess
} from 'components/Authorization';
import { useDispatch, useSelector } from 'react-redux';
import logoMark from 'images/lns-logomark.svg';
import logoText from 'images/lns-logotext-white.svg';
import { setRTL } from 'actions';

interface MainSidebarI {
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  handleLogout: () => void;
}

const MainSidebar: FunctionComponent<MainSidebarI> = ({
  open,
  handleDrawerOpen,
  handleDrawerClose,
  handleLogout
}: MainSidebarI) => {
  const navigate = useNavigate();
  const canAccess = useCanAccess(Resource.SECURITY, Action.READ);
  const [selectedGuid, setSelectedGuid] = useState<string>();

  const lockSidebarOpen = sessionStorage.getItem('lockSidebarOpen');

  const [lockOpen, setLockOpen] = useState<boolean>(!!lockSidebarOpen);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [menuWidth, setMenuWidth] = useState(0);

  const { currentSsoUser, dir } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  const menuRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const toggleRTL = () => {
    const d = (dir ?? i18n.dir()) === 'ltr' ? 'rtl' : 'ltr';
    dispatch(setRTL(d));
  };

  const toggleProfile = () => {
    if (canAccess) {
      navigate(`/administration/security/users/${currentSsoUser.guid}`);
    } else {
      navigate(`/user/${currentSsoUser.guid}`);
    }
  };

  const onClickSettings = (item: { name: string; guid: string }) => {
    setSelectedGuid(item.guid);
    navigate(`/administration/${item.guid}`);
  };

  const onResetAdminSelection = () => {
    setSelectedGuid(undefined);
  };

  const onClickMain = () => {
    onResetAdminSelection();
  };

  const lockDrawerToggle = () => {
    setLockOpen(!lockOpen);
    if (!lockOpen) {
      window.sessionStorage?.setItem('lockSidebarOpen', 'true');
    } else {
      window.sessionStorage?.removeItem('lockSidebarOpen');
      handleDrawerClose();
    }
  };

  const handleDrawerCloseLockable = () => {
    if (!lockOpen) handleDrawerClose();
  };

  const { currentUserPermissions } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  const showAdminSidebar = Object.values(currentUserPermissions).some(
    p => !!p.admin
  );

  const classes = mainSidebarStyles();

  const openDrawer = useCallback(() => {
    if (lockOpen) handleDrawerOpen();
  }, [lockOpen, handleDrawerOpen]);

  const resizeObserver = new ResizeObserver(() => {
    setMenuWidth(menuRef.current?.getBoundingClientRect().width ?? 0);
  });

  if (menuRef.current) {
    resizeObserver.observe(menuRef.current);
  }

  useEffect(() => {
    openDrawer();
  }, [openDrawer]);

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.sidebar, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: clsx(classes.sidebarContent, classes.drawerBg, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
      onMouseEnter={handleDrawerOpen}
      onMouseLeave={handleDrawerCloseLockable}
    >
      <Box className={clsx(classes.title, { [classes.titleOpen]: open })}>
        <img
          className={classes.titleLogo}
          src={logoMark}
          width="50px"
          alt="Lns Logo"
        />
        <img
          className={classes.titleText}
          src={logoText}
          width="50px"
          alt="Lns Logo"
        />
        <IconButton
          className={clsx(classes.titleIcon, {
            [classes.titleIconLocked]: lockOpen
          })}
          onClick={lockDrawerToggle}
        >
          <VerticalSplitRounded color="secondary" />
        </IconButton>
      </Box>
      <Divider />
      <List className={classes.list}>
        <ProtectedSidebarListItem
          title={i18n.t('links.sidebar.myLns')}
          linkPath="/my-lns"
          icon={<HomeOutlined color="secondary" />}
          sideBarOpen={open}
          onClickMain={onClickMain}
        />
        <ProtectedSidebarListItem
          title="Assignments"
          linkPath="/assignments"
          icon={<AssignmentIndOutlined color="secondary" />}
          sideBarOpen={open}
          onClickMain={onClickMain}
          lnsResource={Resource.TASK}
          lnsAction={Action.LIST}
        />
        <ProtectedSidebarListItem
          title={i18n.t('links.sidebar.storyPools')}
          linkPath="/story-pools"
          icon={<ListAltOutlined color="secondary" />}
          sideBarOpen={open}
          onClickMain={onClickMain}
          lnsResource={Resource.STORY_POOL}
          lnsAction={Action.LIST}
        />
        <ProtectedSidebarListItem
          title={i18n.t('links.sidebar.newswires')}
          linkPath="/newswires"
          icon={<RssFeedOutlined color="secondary" />}
          sideBarOpen={open}
          onClickMain={onClickMain}
          lnsResource={Resource.NEWSWIRE}
          lnsAction={Action.LIST}
        />
        <ProtectedSidebarListItem
          title={i18n.t('links.sidebar.rundowns')}
          linkPath="/rundowns"
          icon={<DnsOutlined color="secondary" />}
          sideBarOpen={open}
          onClickMain={onClickMain}
          lnsResource={Resource.RUNDOWN}
          lnsAction={Action.LIST}
        />
        <ProtectedSidebarListItem
          title={i18n.t('links.sidebar.publishFolders')}
          linkPath="/publish-folders"
          icon={<FolderOutlined color="secondary" />}
          sideBarOpen={open}
          onClickMain={onClickMain}
          lnsResource={Resource.PUBLISH_FOLDER}
          lnsAction={Action.LIST}
        />
        <Divider />
      </List>
      <Box style={{ flex: 1 }} />
      {showAdminSidebar && (
        <ProtectedSidebarListItem
          title={i18n.t('links.sidebar.administration')}
          icon={<SettingsOutlined color="secondary" />}
          sideBarOpen={open}
          selected={selectedGuid}
          onClickNestedItem={onClickSettings}
          nestedItems={nestedItems()}
          nestedItemsPath="/administration"
        />
      )}
      {currentSsoUser && (
        <div>
          <div ref={menuRef}>
            <Box
              className={clsx(classes.accountMain, {
                [classes.accountExpanded]: open
              })}
            >
              <IconButton
                className={classes.accountButton}
                aria-label="account of current user"
                aria-controls="account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <Avatar className={classes.accountAvatar}>
                  {currentSsoUser.username[0]}
                </Avatar>
              </IconButton>
              <Box className={classes.accountDetails}>
                <Typography className={classes.accountName}>
                  {currentSsoUser.username}
                </Typography>
                <Typography variant="body2" color="secondary">
                  {currentSsoUser.email}
                </Typography>
              </Box>
              <IconButton
                className={clsx(classes.accountIconBox, {
                  [classes.accountIconBoxOpen]: menuOpen
                })}
                classes={{ label: classes.accountIconBoxLabel }}
                onClick={handleMenuOpen}
              >
                <ExpandLess className={classes.accountIcon} color="secondary" />
                <ExpandMore className={classes.accountIcon} color="secondary" />
              </IconButton>
            </Box>
          </div>

          <Popover
            classes={{ paper: classes.accountMenu }}
            id="account-menu"
            anchorEl={menuRef.current}
            anchorOrigin={{
              vertical: -8,
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            keepMounted
            open={menuOpen}
            onClose={handleMenuClose}
          >
            <Box width={menuWidth}>
              <Box className={classes.accountMenuSubmenu}>
                <MenuItem
                  className={classes.accountMenuItem}
                  onClick={toggleProfile}
                >
                  <PersonOutlined />
                  <Typography color="secondary">
                    {i18n.t('links.navbar.profile')}
                  </Typography>
                </MenuItem>
                <MenuItem
                  className={classes.accountMenuItem}
                  onClick={toggleRTL}
                >
                  {dir === 'ltr' ? (
                    <FormatAlignLeftOutlined />
                  ) : (
                    <FormatAlignRightOutlined />
                  )}
                  <Typography color="secondary">
                    {(dir ?? i18n.dir()).toUpperCase()}
                  </Typography>
                </MenuItem>
              </Box>
              <MenuItem
                className={classes.accountMenuItem}
                onClick={handleLogout}
              >
                <ExitToAppOutlined />
                <Typography color="secondary">
                  {i18n.t('links.navbar.logout')}
                </Typography>
              </MenuItem>
            </Box>
          </Popover>
        </div>
      )}
    </Drawer>
  );
};

export default MainSidebar;
