import { FunctionComponent } from 'react';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { humanize } from 'utils/string';
import shortid from 'shortid';

const BreadCrumbs: FunctionComponent = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line
  const handleClick = (e: any, path: string) => {
    e.preventDefault();
    navigate(path);
  };

  const buildBreadCrumbs = () => {
    const paths = window.location.pathname.split('/');
    paths[0] = 'Home';
    return paths;
  };

  const buildPath = (path: string) => {
    const paths = window.location.pathname.split('/');
    const pathIndex = paths.indexOf(path);
    if (pathIndex < 0) return '/';
    return `${paths.splice(0, pathIndex).join('/')}/${path}`;
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {buildBreadCrumbs().map((path: string, index: number) => {
        if (path === 'administration') {
          return (
            <Typography key={shortid.generate()}>Administration</Typography>
          );
        }

        if (index === buildBreadCrumbs().length - 1) {
          return (
            <Typography key={shortid.generate()}>{humanize(path)}</Typography>
          );
        }

        return (
          <Link
            key={shortid.generate()}
            color="primary"
            href={buildPath(path)}
            // eslint-disable-next-line
            onClick={(e: any) => handleClick(e, buildPath(path))}
          >
            {humanize(path)}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadCrumbs;
