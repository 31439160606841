import i18n from 'i18n';
import { ReactNode } from 'react';
import {
  SettingsOutlined,
  SecurityOutlined,
  InsertDriveFileOutlined,
  SettingsInputSvideoOutlined,
  CategoryOutlined,
  RssFeedOutlined,
  TuneOutlined,
  DnsOutlined,
  DescriptionOutlined,
  TheatersOutlined,
  ListAltOutlined,
  PermMediaOutlined
} from '@material-ui/icons';
import { Resource, Action } from 'components/Authorization';

export const nestedItems = (): {
  name: string;
  guid: string;
  icon: ReactNode;
  lnsResource?: string;
  lnsAction?: string;
}[] => {
  return [
    {
      name: i18n.t('links.sidebarNestedItems.administration.setup'),
      guid: 'setup',
      icon: <TuneOutlined color="secondary" />,
      lnsResource: Resource.SYSTEM_SETUP,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.rundownTemplates'),
      guid: 'rundown-templates',
      icon: <DnsOutlined color="secondary" />,
      lnsResource: Resource.RUNDOWN_TEMPLATE,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.storyTypes'),
      guid: 'story-types',
      icon: <DescriptionOutlined color="secondary" />,
      lnsResource: Resource.STORY_TYPE,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.media'),
      guid: 'media',
      icon: <TheatersOutlined color="secondary" />
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.storyPools'),
      guid: 'story-pools',
      icon: <ListAltOutlined color="secondary" />,
      lnsResource: Resource.STORY_POOL,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.peripherals'),
      guid: 'peripheral-settings',
      icon: <SettingsInputSvideoOutlined color="secondary" />,
      lnsResource: Resource.PERIPHERAL,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.publishFolders'),
      guid: 'publish-folders',
      icon: <PermMediaOutlined color="secondary" />,
      lnsResource: Resource.PUBLISH_FOLDER,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.locations'),
      guid: 'locations',
      icon: <PermMediaOutlined color="secondary" />,
      lnsResource: Resource.LOCATION,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.storyGenres'),
      guid: 'story-genres',
      icon: <CategoryOutlined color="secondary" />,
      lnsResource: Resource.STORY_GENRE,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.security'),
      guid: 'security',
      icon: <SecurityOutlined color="secondary" />,
      lnsResource: Resource.SECURITY,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.fileTypes'),
      guid: 'file-types',
      icon: <InsertDriveFileOutlined color="secondary" />,
      lnsResource: Resource.FILE_TYPE,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.newswires'),
      guid: 'newswires',
      icon: <RssFeedOutlined color="secondary" />,
      lnsResource: Resource.NEWSWIRE,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.socialMediaFeeds'),
      guid: 'social-media-feeds',
      icon: <SettingsOutlined color="secondary" />
    }
  ];
};
