import { FunctionComponent } from 'react';
import { Box, IconButton } from '@material-ui/core';
import {
  EditOutlined,
  DeleteOutlined,
  VisibilityOutlined
} from '@material-ui/icons';
import TableBuilder from 'components/Table';
import i18n from 'i18n';

interface MediaItemListI {
  mediaItems: $Lns.MediaItem[];
  onEdit: (mediaItem: $Lns.MediaItem) => void;
  onRemove: (mediaItem: $Lns.MediaItem) => void;
  onShow: (mediaItem: $Lns.MediaItem) => void;
}

const MediaItemList: FunctionComponent<MediaItemListI> = ({
  mediaItems,
  onEdit,
  onRemove,
  onShow
}: MediaItemListI) => {
  const rows = [
    i18n.t('pages.administration.media.tableRow.name'),
    i18n.t('pages.administration.media.tableRow.type'),
    i18n.t('pages.administration.media.tableRow.sourceUrl'),
    i18n.t('tableRow.actions')
  ];

  const cells = mediaItems.map(mediaItem => {
    return {
      fields: {
        name: { value: mediaItem.name },
        type: { value: mediaItem.type },
        guid: { value: mediaItem.guid, hidden: true },
        sourceUrl: { value: mediaItem.sourceUrl },
        actions: {
          value: (
            <Box>
              <IconButton size="small" onClick={() => onShow(mediaItem)}>
                <VisibilityOutlined color="secondary" />
              </IconButton>
              <IconButton size="small" onClick={() => onEdit(mediaItem)}>
                <EditOutlined color="secondary" />
              </IconButton>
              <IconButton size="small" onClick={() => onRemove(mediaItem)}>
                <DeleteOutlined color="secondary" />
              </IconButton>
            </Box>
          )
        }
      }
    };
  });

  return <TableBuilder rows={rows} cells={cells} />;
};

export default MediaItemList;
