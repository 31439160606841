import { createStyles, makeStyles, Theme } from '@material-ui/core';

const mainLayoutStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    }
  });
});

export default mainLayoutStyles;
