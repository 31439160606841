import React, { createRef, FunctionComponent } from 'react';
import shortid from 'shortid';
import tableStyles from 'styles/table';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { DropResult } from 'react-beautiful-dnd';
import { TableCellI } from 'interfaces/tableCell';
import { StyledTableCell } from 'components/StyledTableCell';
import { StyledTableRow } from 'components/StyledTableRow';
import DroppableComponent from 'components/DroppableComponent';
import DraggableComponent from 'components/DraggableComponent';
import Pagination from './Pagination';

interface TableBuilderI {
  rows: string[];
  cells: TableCellI[];
  rowsPerPage?: number;
  // eslint-disable-next-line
  onRowClick?: (rowItem: any) => void;
  onDragComplete?: (result: DropResult) => void;
  isDraggable?: boolean;
}

const TableBuilder: FunctionComponent<TableBuilderI> = ({
  rows,
  cells,
  rowsPerPage,
  isDraggable,
  onDragComplete,
  onRowClick,
  ...props
}: TableBuilderI) => {
  const classes = tableStyles();
  const tableRef = createRef<HTMLDivElement>();

  const onDragEnd = (result: DropResult) => {
    if (!onDragComplete) return;
    onDragComplete(result);
  };

  const RowClickNotAllow = ['actions'];

  // eslint-disable-next-line
  const onCellClick = (cell: any, fields: any) => {
    if (
      (typeof cell.value === 'string' || typeof cell.value === 'number') &&
      !RowClickNotAllow.includes(cell.value)
    ) {
      if (onRowClick) onRowClick(fields);
    }
  };

  const [page, setPage] = React.useState(1);
  const [rowsPerPageState, setRows] = React.useState(rowsPerPage || 10);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <TableContainer
      className={classes.tableContainer}
      ref={tableRef}
      {...props}
    >
      <Table size="small" stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            {rows.map(row => {
              return (
                <StyledTableCell key={shortid.generate()} align="center">
                  {row}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody component={DroppableComponent(onDragEnd)}>
          {(rowsPerPageState > 0
            ? cells.slice(
                (page - 1) * rowsPerPageState,
                (page - 1) * rowsPerPageState + rowsPerPageState
              )
            : cells
          ).map((cell, index) => {
            const keys = Object.keys(cell.fields);
            const id = shortid.generate();
            return (
              <StyledTableRow
                component={DraggableComponent(id, index, !!isDraggable)}
                selected={cell.selected}
                key={id}
              >
                {keys.map(key => {
                  const cellItem = cell.fields[key];
                  if (cellItem.hidden) return <React.Fragment key={key} />;
                  return (
                    <TableCell
                      key={shortid.generate()}
                      align="left"
                      onClick={() => {
                        onCellClick(cellItem, cell.fields);
                      }}
                    >
                      <Box maxWidth="200px">
                        <Typography
                          variant="inherit"
                          noWrap
                          component="div"
                          color="textSecondary"
                        >
                          {typeof cellItem.value === 'function'
                            ? cellItem.value()
                            : cellItem.value}
                        </Typography>
                      </Box>
                    </TableCell>
                  );
                })}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
      {cells.length > 10 && (
        <Pagination
          count={Math.ceil(cells.length / rowsPerPageState)}
          rowsPerPage={rowsPerPageState}
          onChange={handleChangePage}
          onRowsPerPageChange={setRows}
        />
      )}
    </TableContainer>
  );
};

export default TableBuilder;

TableBuilder.defaultProps = {
  onRowClick: undefined,
  rowsPerPage: 10,
  isDraggable: false,
  onDragComplete: undefined
};
