import { FunctionComponent, ReactNode, useState } from 'react';
import { Box } from '@material-ui/core';
import mainLayoutStyles from 'styles/mainLayout';
import MainSidebar from 'components/MainSidebar';
import BreadCrumbs from 'components/Breadcrumbs';
import CopyRight from 'components/CopyRight';

interface MainLayoutI {
  children: ReactNode;
  handleLogout: () => void;
}

const MainLayout: FunctionComponent<MainLayoutI> = ({
  handleLogout,
  ...props
}: MainLayoutI) => {
  const classes = mainLayoutStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <MainSidebar
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        handleLogout={handleLogout}
      />

      <main className={classes.content}>
        <Box mb={2}>
          <BreadCrumbs />
        </Box>
        {props.children}
        <CopyRight />
      </main>
    </div>
  );
};

export default MainLayout;
