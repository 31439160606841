import { FunctionComponent, useState } from 'react';
import { Box, Button, Divider, Grid, Dialog } from '@material-ui/core';
import { TextFields, VisibilityOutlined } from '@material-ui/icons';
import { DialogueData } from 'pages/stories/interfaces/dialogueForm';
import { AssetDynamic } from 'pages/stories/interfaces/assetDynamic';
import { buildDialogueObj } from 'pages/stories/utils/dialogueObject';
import { secondsToDuration } from 'utils/time';
import { filteredDynamicAssets } from 'pages/stories/utils/asset';
import ProducerDialogue from 'pages/stories/components/Dialogue/ProducerDialogue';
import DialogueForm from 'pages/stories/components/Dialogue/Form';
import TimeLine from 'pages/stories/components/Form/TimeLine';
import EditAssetForm from 'pages/stories/components/Asset/edit';
import i18n from 'i18n';
import DialogueWrapper from 'pages/stories/components/Dialogue/Wrapper';
import { updateDialogueValues } from 'pages/stories/components/utils';
import { StoryFormData } from 'pages/stories/interface';
import ContextMenu from 'components/contextMenu';

interface StoryScriptFormI {
  dynamicAssets: AssetDynamic[];
  fullScreen: boolean;
  formData: StoryFormData;
  storyLock: boolean;
  calculatedDuration: number;
  onAddDialogueAsset: (dialogue: AssetDynamic) => void;
  onUpdateDynamicAssets: (assetsNew: AssetDynamic[]) => void;
  onToggleAddMedia: (val: boolean) => void;
}

interface CSSPositionOffsets {
  top: number;
  left: number;
}

const StoryScriptForm: FunctionComponent<StoryScriptFormI> = ({
  dynamicAssets,
  fullScreen,
  formData,
  storyLock,
  calculatedDuration,
  onAddDialogueAsset,
  onUpdateDynamicAssets,
  onToggleAddMedia
}: StoryScriptFormI) => {
  const [dialogueAsset, setDialogueAsset] = useState<DialogueData>();
  const [visiable, setVisiable] = useState<boolean>();
  const [positionMenu, setPositionMenu] = useState<CSSPositionOffsets>();

  const [presenterdialogue, setPresenterdialogue] = useState<AssetDynamic[]>(
    []
  );

  const [editAssetMod, setEditAssetMod] = useState<AssetDynamic>();

  const onAddDialogue = () => {
    const newScriptItem = {
      presenter: '',
      camera: 1,
      dialogue: '',
      instructions: '',
      readRate: 3
    };
    setDialogueAsset(newScriptItem);
  };

  const onAddDialoguetoAsset = (key: number) => {
    const newScriptItem = {
      presenter: '',
      camera: 1,
      dialogue: '',
      instructions: '',
      readRate: 3,
      parentAsset: key
    };
    setDialogueAsset(newScriptItem);
  };

  const onShowPresenter = () => {
    setPresenterdialogue(dynamicAssets);
  };

  const onCancelPresenter = () => {
    setPresenterdialogue([]);
  };

  const onCancel = () => {
    setDialogueAsset(undefined);
  };

  const onEditAssetCancel = () => {
    setEditAssetMod(undefined);
  };

  const onEditAssetFunc = (asset: AssetDynamic, initVal: AssetDynamic) => {
    const dialogueObj = [...dynamicAssets];
    dialogueObj.forEach((pin, key) => {
      if (
        pin.assetI &&
        asset.assetI &&
        initVal.assetI &&
        initVal.assetI.positionInScript === pin.assetI.positionInScript &&
        dialogueObj[key] &&
        dialogueObj[key].assetI
      ) {
        dialogueObj[key].assetI = { ...asset.assetI };
      }
    });
    const temPAsset = [...dialogueObj];
    onUpdateDynamicAssets(temPAsset);
    setEditAssetMod(undefined);
  };

  const onSaveDialogue = (scriptObj: DialogueData, user: $Lns.User) => {
    let position = 0;

    if (dynamicAssets.length > 0) {
      dynamicAssets.forEach(item => {
        let itemPos = -1;
        if (item.assetS) itemPos = item.assetS?.positionInScript;
        else if (item.assetI) itemPos = item.assetI?.positionInScript;

        if (itemPos >= 0 && itemPos > position) {
          position = itemPos;
        }
      });
      position += 1;
    }

    const tempDialogue = buildDialogueObj(scriptObj, user, position);
    onAddDialogueAsset({ assetS: tempDialogue });
  };

  const onUpdateDialogue = (
    scriptObj: DialogueData,
    initVals: DialogueData
  ) => {
    const dialogueObjs = updateDialogueValues(
      dynamicAssets,
      scriptObj,
      initVals
    );

    const updatedAssetList = [...dialogueObjs];
    onUpdateDynamicAssets(updatedAssetList);
  };

  const onEditDialogue = (scriptObj: DialogueData) => {
    setDialogueAsset(scriptObj);
  };

  const onEditAsset = (assetI: AssetDynamic) => {
    setEditAssetMod(assetI);
  };

  const onDeleteAsset = (index: number, child?: boolean) => {
    const updatedDynamicAssets = filteredDynamicAssets(
      child,
      dynamicAssets,
      index
    );
    updatedDynamicAssets.splice(index, 1);
    onUpdateDynamicAssets([...updatedDynamicAssets]);
  };

  const contextMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (fullScreen) {
      e.preventDefault();
      if (e.type === 'contextmenu') {
        setVisiable(true);
        const left = e.clientX;
        const top = e.clientY;

        const positionMenuObj = {
          top,
          left
        };
        setPositionMenu({ ...positionMenuObj });
      } else {
        setVisiable(false);
      }
    }
  };

  const makeMenuBtns = () => {
    const btns = [{ title: 'Add Media', action: () => onToggleAddMedia(true) }];
    return btns;
  };

  return (
    <Grid container spacing={1} direction="row">
      <Grid item container spacing={0}>
        <Box display="grid" width="50%" justifyContent="flex-start">
          <Grid item xl={3}>
            <DialogueWrapper
              title={i18n.t('pages.stories.formLabel.calculatedDuration')}
              value={secondsToDuration(calculatedDuration)}
            />
          </Grid>
        </Box>
        <Box display="grid" width="50%" justifyContent="flex-end">
          <Grid item container spacing={1}>
            <Grid item>
              <Button
                disabled={storyLock}
                color="primary"
                variant="contained"
                size="small"
                name="addText"
                startIcon={<TextFields />}
                onClick={onAddDialogue}
              >
                {i18n.t('pages.stories.button.addText')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={storyLock}
                color="primary"
                variant="contained"
                size="small"
                startIcon={<VisibilityOutlined />}
                onClick={onShowPresenter}
              >
                {i18n.t('pages.stories.button.showProducerScript')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Box width="inherit" onClick={contextMenu} onContextMenu={contextMenu}>
        <Grid item container spacing={2} direction="row">
          {visiable && (
            <Box
              position="absolute"
              {...positionMenu}
              aria-controls="simple-menu"
              aria-haspopup="true"
            >
              <ContextMenu items={makeMenuBtns()} />
            </Box>
          )}
          <Grid item xs={fullScreen ? 6 : 12}>
            <TimeLine
              storyLock={storyLock}
              dynamicAssets={dynamicAssets}
              onEditDialog={onEditDialogue}
              onUpdateDialogue={onUpdateDialogue}
              onEditAsset={onEditAsset}
              onDeleteAsset={onDeleteAsset}
              onAddDialogue={onAddDialoguetoAsset}
            />
          </Grid>
          {fullScreen && (
            <Grid item xs={6}>
              <ProducerDialogue initVal={dynamicAssets} formData={formData} />
            </Grid>
          )}
        </Grid>
      </Box>
      {dialogueAsset && (
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={onCancel}
          aria-labelledby="simple-dialog-title"
          open
        >
          <DialogueForm
            initVal={dialogueAsset}
            onSave={onSaveDialogue}
            onEdit={onUpdateDialogue}
            onCancel={onCancel}
          />
        </Dialog>
      )}
      {presenterdialogue.length > 0 && (
        <Dialog
          fullWidth
          maxWidth="md"
          onClose={onCancelPresenter}
          aria-labelledby="simple-dialog-title"
          open
        >
          <ProducerDialogue initVal={presenterdialogue} formData={formData} />
        </Dialog>
      )}
      {editAssetMod && (
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={onEditAssetCancel}
          aria-labelledby="simple-dialog-title"
          open
        >
          <EditAssetForm
            initVal={editAssetMod}
            onEdit={onEditAssetFunc}
            onCancel={onEditAssetCancel}
          />
        </Dialog>
      )}
    </Grid>
  );
};

export default StoryScriptForm;
