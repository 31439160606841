import { IconButton, IconButtonProps } from '@material-ui/core';
import { forwardRef } from 'react';
import { useCanAccess } from 'components/Authorization/utils';
import i18n from 'i18n';
import TooltipWrap from 'components/TooltipWrap';

interface ProtectedIconButtonI extends IconButtonProps {
  lnsResource?: string;
  lnsAction?: string;
  tooltip?: string;
  lnsOwners?: string[];
}

export const ProtectedIconButton = forwardRef(
  ({
    lnsResource,
    lnsAction,
    tooltip,
    lnsOwners,
    ...props
  }: ProtectedIconButtonI) => {
    const canAccess = useCanAccess(lnsResource, lnsAction, lnsOwners);

    if (canAccess) {
      if (tooltip) {
        return (
          <TooltipWrap title={tooltip}>
            <IconButton {...props} />
          </TooltipWrap>
        );
      }
      return <IconButton {...props} />;
    }
    return (
      <TooltipWrap title={i18n.t('authorization.tooltip.notAuthorized')}>
        <IconButton {...props} disabled />
      </TooltipWrap>
    );
  }
);

ProtectedIconButton.defaultProps = {
  lnsResource: undefined,
  lnsAction: undefined,
  tooltip: undefined,
  lnsOwners: []
};
