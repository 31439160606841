import { FunctionComponent, BaseSyntheticEvent } from 'react';
import TableBuilder from 'components/Table';
import i18n from 'i18n';
import { Tooltip, Box } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';
import {
  ProtectedIconButton,
  Resource,
  Action,
  useWithPermissions
} from 'components/Authorization';
import shortid from 'shortid';

interface PublishFoldersI {
  publishFolders: $Lns.PublishFolder[];
  onRowClick: (rowItem: {
    guid: { value: string };
    name: { value: string };
  }) => void;
  onEditClick: (PublishFolder: $Lns.PublishFolder) => void;
}

const PublishFolders: FunctionComponent<PublishFoldersI> = ({
  publishFolders,
  onRowClick,
  onEditClick
}: PublishFoldersI) => {
  const rows = [
    i18n.t('pages.administration.publishFolders.tableRow.name'),
    i18n.t('tableRow.actions')
  ];

  const onRowClickWithPermissions = useWithPermissions(
    onRowClick,
    Resource.PUBLISH_FOLDER,
    Action.READ
  );

  const buildActionBtns = (PublishFolder: $Lns.PublishFolder) => {
    const buttons = [];
    if (onEditClick) {
      buttons.push(
        <Tooltip key={shortid()} title="Edit Story" aria-label="edit">
          <ProtectedIconButton
            size="small"
            aria-label="edit"
            onClick={(e: BaseSyntheticEvent) => {
              e.stopPropagation();
              onEditClick(PublishFolder);
            }}
            lnsResource={Resource.PUBLISH_FOLDER}
            lnsAction={Action.EDIT}
          >
            <EditOutlined color="secondary" />
          </ProtectedIconButton>
        </Tooltip>
      );
    }

    return buttons;
  };

  const cells = publishFolders.map(publishFolder => {
    return {
      fields: {
        name: { value: publishFolder.name },
        guid: { value: publishFolder.guid, hidden: true },
        actions: {
          value: <Box display="flex">{buildActionBtns(publishFolder)}</Box>
        }
      }
    };
  });

  return (
    <TableBuilder
      rows={rows}
      cells={cells}
      onRowClick={onRowClickWithPermissions}
    />
  );
};

export default PublishFolders;
