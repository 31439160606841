import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Box, Chip } from '@material-ui/core';
import { ReportProblemOutlined } from '@material-ui/icons';
import { format } from 'date-fns';
import i18n from 'i18n';
import TooltipWrap from 'components/TooltipWrap';
import { findTaskByGuid, LnsTaskToFormData } from 'pages/assignments/utils';
import PriorityTag from 'pages/assignments/components/PriorityTag';
import ActionButtons from 'pages/assignments/components/AssignmentList/ActionButtons';
import colors from 'styles/colors';
import TableBuilder from 'components/Table';
import themeStyle from 'styles/theme';

interface ListAssignmentsI {
  isAdmin: boolean;
  currentLnsUser: $Lns.User;
  assignments: $Lns.Task[];
  onViewAssignment: (assignment: $Lns.Task) => void;
  onEditAssignment: (assignment: $Lns.Task) => void;
  onDeleteAssignment: (assignmentGuid: string) => void;
}

const ListAssignments: FunctionComponent<ListAssignmentsI> = ({
  isAdmin,
  assignments,
  currentLnsUser,
  onViewAssignment,
  onEditAssignment,
  onDeleteAssignment
}: ListAssignmentsI) => {
  const onHandleView = (guid: string) => {
    const assignment = findTaskByGuid(guid, assignments);
    if (assignment) onViewAssignment(assignment);
  };

  const onHandleEdit = (guid: string) => {
    const assignment = findTaskByGuid(guid, assignments);
    if (assignment) onEditAssignment(assignment);
  };

  const onHandleDelete = (guid: string) => {
    const assignment = findTaskByGuid(guid, assignments);
    if (assignment) onDeleteAssignment(assignment.guid);
  };

  const buildActionBtns = (assignment: $Lns.Task) => {
    const currentCellAssignment = findTaskByGuid(assignment.guid, assignments);

    if (!currentCellAssignment) return <p>{i18n.t('data.error')}</p>;

    return (
      <ActionButtons
        isAdmin={isAdmin}
        assignment={LnsTaskToFormData(currentCellAssignment)}
        currentLnsUser={currentLnsUser}
        onHandleView={onHandleView}
        onHandleEdit={onHandleEdit}
        onHandleDelete={onHandleDelete}
      />
    );
  };

  const cells = assignments.map(assignment => {
    return {
      fields: {
        priority: { value: <PriorityTag priority={assignment.priority} /> },
        title: { value: assignment.title.toUpperCase() },
        story: {
          value:
            assignment.story instanceof Object && assignment.story.guid ? (
              <Link
                to={`/my-lns/${assignment.story.guid}/edit`}
                style={{ color: colors.primary[500] }}
              >
                {assignment.story.slug}
              </Link>
            ) : (
              'Not Set'
            )
        },
        dueDate: {
          value: (
            <Box display="flex" whiteSpace="break-spaces">
              {new Date(assignment.dateDue) < new Date() && (
                <Box paddingRight={1}>
                  <TooltipWrap
                    title={i18n.t('pages.assignments.tooltip.overdue')}
                  >
                    <ReportProblemOutlined
                      style={{ color: themeStyle.palette.warning.main }}
                    />
                  </TooltipWrap>
                </Box>
              )}
              <TooltipWrap title={format(new Date(assignment.dateDue), 'Pp')}>
                {format(new Date(assignment.dateDue), 'Pp')}
              </TooltipWrap>
            </Box>
          )
        },
        taskState: {
          value: (
            <Chip
              label={
                assignment.taskState instanceof Object
                  ? assignment.taskState.name
                  : 'N/A'
              }
              style={{ maxWidth: '100%' }}
            />
          )
        },
        assignedTo: {
          value:
            assignment.assignedTo instanceof Object
              ? assignment.assignedTo.username
              : 'Not Set'
        },
        actions: { value: buildActionBtns(assignment) }
      }
    };
  });

  const rows = [
    i18n.t('pages.assignments.formLabel.priority'),
    i18n.t('pages.assignments.formLabel.title'),
    i18n.t('pages.assignments.formLabel.storySlug'),
    i18n.t('pages.assignments.formLabel.dateDue'),
    i18n.t('pages.assignments.formLabel.taskStates'),
    'Assignee',
    '' // Actions
  ];

  return <TableBuilder rows={rows} cells={cells} isDraggable />;
};

export default ListAssignments;
