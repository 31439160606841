import { createStyles, makeStyles } from '@material-ui/core';
import colors from 'styles/colors';
import themeStyle from 'styles/theme';

const storiesStyles = makeStyles(() =>
  createStyles({
    card: {
      boxShadow: '-2px 0px 2px rgba(0, 0, 0, 0.15)'
    }
  })
);
export const scriptRowStyle = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
      height: 40
    },
    scriptSection: {
      display: 'flex',
      width: '100%',
      borderTop: '2px solid #b0cad8',
      background: 'inherit'
    }
  })
);

export const generalStyle = makeStyles(theme =>
  createStyles({
    paddingTopOnly: {
      padding: theme.spacing(0),
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  })
);

export const storyRowStyles = makeStyles(() =>
  createStyles({
    finished: {
      opacity: 0.2
    },
    active: {
      backgroundColor: themeStyle.palette.success.main
    },
    default: {
      backgroundColor: 'inherit'
    }
  })
);

export const progressStyle = makeStyles(() =>
  createStyles({
    root: {
      height: '10px'
    },
    colorPrimary: {
      background: colors.primary[100]
    },
    barColorPrimary: {
      background: colors.primary[500]
    }
  })
);

export const snackbarStyles = makeStyles(() =>
  createStyles({
    anchorOriginTopCenter: {
      zIndex: 1201,
      top: '1%',
      background: colors.primary[500],
      padding: '10px'
    }
  })
);

export default storiesStyles;
