const colors = {
  base: {
    white: '#ffffff',
    black: '#000000'
  },
  gray: {
    25: '#fdfdfd',
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#e9eaeb',
    300: '#d5d7da',
    400: '#a4a7ae',
    500: '#717680',
    600: '#535862',
    700: '#414651',
    800: '#252b37',
    900: '#181d27',
    950: '#0a0d12'
  },
  primary: {
    25: '#f5f8ff',
    50: '#eef4ff',
    100: '#e0eaff',
    200: '#c7d7fe',
    300: '#a4bcfd',
    400: '#8098f9',
    500: '#6172f3',
    600: '#444ce7',
    700: '#3538cd',
    800: '#2d31a6',
    900: '#2d3282',
    950: '#1f235b'
  },
  secondary: {
    50: '#dcf4f9',
    100: '#bae9f3',
    200: '#99dfed',
    300: '#77d4e7',
    400: '#56cae1',
    500: '#34bfdb',
    600: '#2a99af',
    700: '#1f7383',
    800: '#154c58',
    900: '#0a262c'
  },
  text: {
    secondary: '#084d74'
  },
  neutral: {
    0: '#fff',
    5: '#f4f4f4',
    10: '#e4e5e6',
    20: '#caccce',
    40: '#797a7c',
    60: '#515252',
    80: '#282929'
  },
  success: {
    25: '#f6fef9',
    50: '#ecfdf3',
    100: '#dcfae6',
    200: '#abefc6',
    300: '#75e0a7',
    400: '#47cd89',
    500: '#17b26a',
    600: '#079455',
    700: '#067647',
    800: '#085d3a',
    900: '#074d31',
    950: '#053321'
  },
  warning: {
    25: '#fffcf5',
    50: '#fffaeb',
    100: '#fef0c7',
    200: '#fedf89',
    300: '#fec84b',
    400: '#fdb022',
    500: '#f79009',
    600: '#dc6803',
    700: '#b54708',
    800: '#93370d',
    900: '#7a2e0e',
    950: '#4e1d09'
  },
  info: {
    light: '#e6f3fb',
    medium: '#94cded',
    dark: '#2d9cdb'
  },
  error: {
    25: '#fffbfa',
    50: '#fef3f2',
    100: '#fee4e2',
    200: '#fecdca',
    300: '#fda29b',
    400: '#f97066',
    500: '#f04438',
    600: '#d92d20',
    700: '#b42318',
    800: '#912018',
    900: '#7a271a',
    950: '#55160c'
  },
  backgrounds: {
    primary: '#eaf2f5',
    secondary: '#34bfdb',
    helper: '#fafafa',
    grey: '#fcf9f4',
    grey10: '#f5f3ef',
    grey20: '#f2efeb',
    grey30: '#ede9e3',
    grey40: '#e8e3db',
    grey50: '#e3ded2'
  },
  opacities: {
    dark: {
      5: '#000, 05%',
      10: '#000, 10%',
      20: '#000, 20%',
      40: '#000, 40%',
      80: '#000, 80%'
    },
    light: {
      5: '#fff, 05%',
      10: '#fff, 10%',
      20: '#fff, 20%',
      40: '#fff, 40%',
      80: '#fff, 80%'
    }
  },
  shadows: {
    soft: '2, 0, 2, 15%',
    medium: '8, 0, 4, 15%',
    strong: '16, 0, 8, 20%'
  }
};

export default colors;
