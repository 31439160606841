import type { FunctionComponent, SyntheticEvent } from 'react';
import shortid from 'shortid';
import paginationStyles from 'styles/pagination';
import {
  Button,
  ButtonGroup,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { usePagination } from '@material-ui/lab/Pagination';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import clsx from 'clsx';

interface PaginationI {
  count: number;
  rowsPerPage?: number;
  onChange?: (page: number) => void;
  onRowsPerPageChange?: (rows: number) => void;
}

const Pagination: FunctionComponent<PaginationI> = ({
  count,
  rowsPerPage,
  onChange,
  onRowsPerPageChange
}: PaginationI) => {
  const paginationClasses = paginationStyles();

  const { items } = usePagination({ count });

  const handleChange = async (
    event: SyntheticEvent<Element, Event>,
    newPage: number,
    paginationEvent: (event: SyntheticEvent<Element, Event>) => void
  ) => {
    let boundPage = newPage;

    if (newPage > count) {
      boundPage = count;
    } else if (newPage < 1) {
      boundPage = 1;
    }

    paginationEvent(event);
    if (onChange) onChange(boundPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    if (
      event &&
      onRowsPerPageChange &&
      !Number.isNaN(event.target.value as number)
    ) {
      onRowsPerPageChange(event.target.value as number);
    }
  };

  return (
    <div className={paginationClasses.row}>
      {typeof rowsPerPage !== 'undefined' && (
        <div className={paginationClasses.perPage}>
          <Typography className={paginationClasses.perPageLabel}>
            Rows per page
          </Typography>
          <Select
            className={paginationClasses.perPageSelect}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={rowsPerPage}
            disableUnderline
            onChange={handleRowsPerPageChange}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={50}>100</MenuItem>
          </Select>
        </div>
      )}
      <ButtonGroup aria-label="outlined  button group">
        {items.map(
          ({
            page: paginationPage,
            type,
            selected,
            onClick: onItemClick,
            ...item
          }) => {
            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
              return (
                <Button key={shortid()} className={paginationClasses.button}>
                  …
                </Button>
              );
            }

            if (type === 'page') {
              return (
                <Button
                  key={shortid()}
                  className={clsx(paginationClasses.button, {
                    [paginationClasses.buttonSelected]: selected
                  })}
                  type="button"
                  {...item}
                  onClick={event =>
                    handleChange(event, paginationPage, onItemClick)
                  }
                >
                  {paginationPage}
                </Button>
              );
            }

            return (
              <Button
                key={shortid()}
                className={paginationClasses.button}
                type="button"
                {...item}
                onClick={event =>
                  handleChange(event, paginationPage, onItemClick)
                }
              >
                <div className={paginationClasses.buttonLabel}>
                  {type === 'previous' && <ArrowBack />}
                  {type}
                  {type === 'next' && <ArrowForward />}
                </div>
              </Button>
            );
          }
        )}
      </ButtonGroup>
    </div>
  );
};

export default Pagination;

Pagination.defaultProps = {
  rowsPerPage: undefined,
  onChange: undefined,
  onRowsPerPageChange: undefined
};
