import { FunctionComponent, ReactNode } from 'react';
import { Box } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';
import i18n from 'i18n';
import TableBuilder from 'components/Table';
import {
  ProtectedDeleteDialog,
  ProtectedIconButton,
  Resource,
  Action
} from 'components/Authorization';

interface LocationListI {
  locations: $Lns.Location[];
  onEdit: (location: $Lns.Location) => void;
  onDelete: (location: $Lns.Location) => void;
}

const LocationList: FunctionComponent<LocationListI> = ({
  locations,
  onEdit,
  onDelete
}: LocationListI) => {
  const rows = [
    i18n.t('pages.administration.locations.tableRow.city'),
    i18n.t('pages.administration.locations.tableRow.country'),
    i18n.t('tableRow.actions')
  ];

  const cells = locations.map(location => {
    const props = {
      fields: {
        city: { value: location.city },
        country: { value: location.country }
      }
    } as { fields: { [key: string]: { value: string | ReactNode } } };

    // eslint-disable-next-line
    props.fields.actions = {
      value: (
        <Box display="flex">
          <ProtectedIconButton
            size="small"
            onClick={() => onEdit(location)}
            lnsResource={Resource.LOCATION}
            lnsAction={Action.EDIT}
          >
            <EditOutlined color="secondary" />
          </ProtectedIconButton>
          <ProtectedDeleteDialog
            size="small"
            lnsResource={Resource.LOCATION}
            onDelete={() => {
              onDelete(location);
            }}
            target={location.city}
            btnDisabled={false}
          />
        </Box>
      )
    };

    return props;
  });

  return <TableBuilder rows={rows} cells={cells} />;
};

export default LocationList;
