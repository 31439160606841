import { createStyles, makeStyles, Theme } from '@material-ui/core';
import colors from './colors';

const sideBarListItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      boxSizing: 'content-box',
      height: theme.spacing(3),
      width: theme.spacing(3),
      padding: theme.spacing(1),
      borderRadius: '6px',

      '&:hover': {
        background: colors.gray[700]
      },

      '&.Mui-selected': {
        background: colors.gray[800],

        '&:hover': {
          background: colors.gray[700]
        }
      }
    },
    open: {
      /** 100% doesn't work well with `box-sizing: content-box but`
       * styling the listItems without content-box would've been a bigger headache */
      width: `calc(100% - ${theme.spacing(2)}px)`
    },
    collapse: {
      overflowX: 'auto'
    },
    listIcon: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      minWidth: 0,
      marginRight: theme.spacing(3)
    },
    openIcon: {
      minWidth: 0,
      marginRight: theme.spacing(1)
    },
    nested: {
      paddingLeft: theme.spacing(4)
    }
  })
);

export default sideBarListItemStyles;
